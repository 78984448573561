@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,800;1,900&display=swap");



.side-container {
  top: 0px;
  height: 100vh;
  padding: 0px !important;
}


.Heading {
  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  color: #7253A2;
  font-style: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.Heading span{
  margin-right: 10px;
  text-decoration: underline;
}
.inner-text {
  font-family: Nunito;
  font-size: 14px;
  text-align: justify;
  margin-top:8px;
  color: #000000;
}

.circle {
  background: #c4c4c4;
  border-radius: 100%;
  width: 102px;
  height: 102px;
}
.circle img {
  border-radius: 50% 50% 50% 50%;
  width: 102px;
  height: 102px;
  object-fit: cover;
}
.circle-img-info{
  /* width: 102px;
  height: 102px; */
}
.question-buttons {
  float: right;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.1px;
  /* margin-top: 40px;
  padding-right: 35px; */
}
.question-buttons button {
  width: 223px;
  height: 58px;
  font-weight: bold;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.start-wizard-footer{
  /* padding-right: 100px; */
}

