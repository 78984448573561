@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,800;1,900&display=swap');
@import './stepPage.scss';
@import './assessmentform.scss';
@import './wireframe.scss';
@import './result.scss';

body{
    margin: 0;
    padding: 0;
}
.container-main{
    font-family: Nunito;
    margin: 0 !important;
    background-image:url(../images/background.png);
    background-size: cover;
    background-position: inherit;
    width: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    padding: 0;
    overflow-y: auto;
}
.container-result-main{
    font-family: Nunito;
    margin: 0 !important;
    /* background-image:url(../images/background.png); */
    /* background-size: cover; */
    /* background-position: center; */
    width: 100%;
    /* background-repeat: no-repeat; */
    height: 100vh;
    padding: 0;
    overflow: hidden;
}
.link-text-result{
    color: #FCB333;
    font-weight: 700;
    margin-left: auto;
    font-size: 18px;
    cursor: pointer;
}
.ant-popover{
    width: 20%;
}
/* .popover-assessment .ant-popover .ant-popover-placement-bottomRight{
    width: 300px;
    height: auto;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}
.side-result-container {
  margin-top: 50px;
  top: 0px;
  height: 90vh;
  padding: 0px !important;
  overflow-y: auto;
  scrollbar-width: thin;
}
.side-result-container::-webkit-scrollbar {
  width: 10px;
}
.side-result-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #ffffff80;
  /* background-color:#F5F6F8; */
}
.side-result-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
}
.step-text-main {
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 32.6px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 5px;
}
.step-text-main span {
  font-size: 30px;
  font-weight: 600;
}
.wire-logo-div {
  width: 184px;
  height: 61px;
  left: 75px;
  top: 50px;
  border-radius: 10px;
  border: none;
  position: fixed;
}
.logo-col {
  height: 80px;
}
.side-container {
  top: 0px;
  height: 100vh;
  padding: 0px !important;
  float: right;
}
.container {
    width: 100%;
    padding: 50px 30px 20px 20px;
  }
.container-result{
    width: 100%;
    padding: 0px 30px 20px 20px;
}
.container-result-proceed{
    width: 100%;
    padding: 50px 20px 20px 20px;
}
.steps-content{
    padding-right: 100px;
}
.question-validation-span{
    color: rgb(255, 153, 0);
    font-size: 16px;
    line-height: 1.5715;
}
.result-subheading-container{
    padding: 10px 20px;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
}
.button-getting-started{
    margin-top: 15px;
    margin-right: 10px;
    float: right;
    font-family: Nunito;    
    margin-bottom: 20px;
    color: #7253A2;
    font-weight: 800;
    font-size: 18px;
}

.getting-start-button {
  /* width: 223px;
    height: 58px; */
  padding: 10px 35px;
  font-weight: bold;
  background: #fcb333;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.col-contentcard-cards {
  height: 220px;
  border-radius: 10px;
  margin-top: 20px;
  margin-right: 20px;
  /* cursor: pointer; */
}
.col-contentcard-cards:hover {
  /* border: 4px solid #d48c0e; */
}

.active-card {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.1);
  border: 4px solid #d48c0e;
}
.active-card-proceed {
  -ms-transform: scale(1.04); /* IE 9 */
  -webkit-transform: scale(1.04); /* Safari 3-8 */
  transform: scale(1.03);
  border: 4px solid #d48c0e;
}

.personalize-form {
  margin-top: 30px;
  padding-left: 0px !important;
}

.question-container {
  padding: 30px 0px 0px 0px;
  margin-top: 20px;
  font-family: Nunito;
}
.result-question-container {
  font-family: Nunito;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.result-question-container .ant-row {
  margin-top: 10px;
  margin-bottom: 10px;
}
.qualification-form-textarea textarea {
  width: 100%;
  line-height: 30px;
  border-radius: 10px;
  background-color: #f5f6f8;
  padding: 10px;
  font-size: 18px;
  scrollbar-width: thin;
}
.qualification-form{
    padding-right: 25px !important;
}

.qualification-form-radio label {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0em;
}
.qualification-form .ant-form-item-control-input-content input {
  opacity: 0 !important;
}
.qualification-form-checkbox span {
  color: #fff;
}

.personalize-form-text {
  color: #fff;
  margin-top: 50px;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  font-family: Nunito;
}
.personalize-form-text span {
  color: rgb(255, 153, 0);
}
.personalize-form .ant-form-item-control-input-content input {
  opacity: 1;
  background: transparent;
  border: none;
  border-bottom: groove;
  color: #fff;
  font-size: 16px;
}
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent;
  opacity: 1;
}
.personalize-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  opacity: 1;
  background: transparent;

}

.personalize-form
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  opacity: 1;
  background: transparent;
}
.personalize-form .ant-form-item-control-input-content input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.personalize-form .ant-select .ant-select-selector {
  opacity: 1;
  background: transparent;
  border: none;
  border-bottom: groove;
  color: #fff;
  font-size: 16px;
}
.ant-select-selector {
  border-color: #fff !important;
  box-shadow: none !important;
}

.personalize-form.ant-form-item-has-error {
  background-color: none;
}

.parent-form .parent-name .ant-form-item-label label {
  font-family: Nunito;
  font-weight: 400;
  line-height: 19.1px;
  font-size: 14px;
  font-style: italic;
}
.parent-form .ant-checkbox {
  padding: 0px 0px 1px 0px;
}

.ant-form-vertical .ant-form-item-label > label {
  color: #fff;
  font-size: 18px;
  font-family: Nunito;
  font-weight: 700;
  font-style: normal;
}
.personalize-form-container {
  margin-top: 50px;
  padding-right: 100px;
}
.personalize-form .ant-select-arrow {
  color: white;
}
.row-contentcard {
  padding: 10px 20px;
  background-color: #f5f6f8;
  border-radius: 10px;
  margin-top: 20px;
}
.row-contentcard-proceed {
  padding: 15px;
  background-color: #f5f6f8;
  border-radius: 10px;
  margin-top: 40px;
  cursor: pointer;
}
.row-contentcard-proceed:hover {
  transform: scale(1.03);
  border: 4px solid #d48c0e;
}
.proceed-icon {
  width: 75px;
  height: 75px;
  /* margin-left: 20px; */
  margin-top: 15px;
}
.proceed-icon {
  animation: heartBeat;
  animation-duration: 2s;
}
.heading-proceed {
  font-family: Nunito;
  font-weight: 700;
  font-size: 18px;
  color: #7253a2;
  margin-top: 20px;
}
.inner-text-proceed {
  font-family: Nunito;
  font-size: 14px;
  text-align: justify;
  font-style: normal;
  margin-top: 12px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 30px;
}
.row-contentcard-proceed .circle {
  background: #c4c4c4;
  border-radius: 100%;
  width: 102px;
  height: 102px;
}
.row-contentcard-proceed .circle img {
  border-radius: 50% 50% 50% 50%;
  width: 102px;
  height: 102px;
  object-fit: cover;
}
.content-card-proceed {
  margin-top: 50px;
}
.personalize-form .ant-row {
  margin-top: 10px;
}
.personalize-form-gender-col {
  padding: 0px 0px 0px 20px;
}
.personalize-form-age-col {
  padding: 0px 20px 0px 0px;
}

.parent-form-firstname-col {
  padding: 0px 20px 0px 0px;
}
.parent-form-firstname-col .ant-form-item-label > label {
  margin-top: 5px;
}
.parent-form-lastname-col .ant-form-item-label > label {
  margin-top: 5px;
}
.parent-form-lastname-col {
  padding: 0px 0px 0px 20px;
}
.parent-form .ant-input-group-addon {
  background: transparent !important;
  border: none;
  border-bottom: groove;
}
.parent-form .ant-select-selector {
  border: none !important;
}
.checkbox-parent-form input {
  opacity: 0 !important;
}
.personalize-form .gender-select-input .ant-form-item-control-input {
  padding: 3px 0px;
}
.personalize-form .country-select-input .ant-form-item-control-input {
  padding: 4px 0px;
}
.checkbox-parent-form span {
  /* color: #ffffff; */
  font-size: 18px;
  font-family: Nunito;
  font-style: normal;
  line-height: 22.5px;
  font-weight: 400;
}
/* .parent-form .ant-checkbox-inner {
    border-radius: 50%;
}  */
/* .parent-form .ant-checkbox-checked::after{
    border-radius: 50% !important;
} */
.hide-button {
  display: none;
}

.button-getting-launched{
    margin-top: 15px;
    /* margin-right: 20px; */
    float: right;
    color: #7253A2;
    font-size: 18px;
    margin-bottom: 20px;
}
.getting-launched-button{
    width: 200px;
    height: 50px;
    /* padding:20px; */
    font-weight: bold;
    background: #FCB333;
    border-radius: 10px;
    border:none;
    cursor:pointer;
}
.ant-progress .ant-progress-steps-item{
    width: 80px !important;
    border-radius: 10px;
    margin: 10px;
    background-color: rgba(255, 255, 255, 0.6);
}
.ant-progress{
    margin-top: 28px;
    margin-bottom: 20px;
}

.parent-progress{
  float: right;
}

.button-footer{
    margin-top: 28px;
}
.previous-button{
    margin-top: 28px;
    /* margin-left: 12px; */
}
.previous-parent-button{
  margin-left: 12px;
}
.previous-button a{
    font-size: 18px;
    font-family: Nunito;
    font-weight: bold;
    color: #FCB333;
}
.leftoutlined-div{
    font-size: 30px;
    font-weight: bold;
    color: #d5b1d3;
    margin: 20px 30px;
}
/* .leftoutlined-div:hover{
    color: #FCB333;
    font-size: 40px;
} */
.rightoutlined-div {
  font-size: 30px;
  font-weight: bold;
  color: #d5b1d3;
  margin: 20px 20px 20px 70px;
}
/* .rightoutlined-div:hover{
    color: #FCB333;
    font-size: 40px;
} */
.footer-text-qualification-form {
  margin-top: 20px;
  /* margin-left: 20px; */
  font-family: Nunito;
  /* font-weight: 400; */
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  color: #ffffff;
}
.footer-text-qualification-form span {
  font-weight: 800;
  font-family: Nunito;
  font-style: normal;
  color: #fcb333;
}
.ant-row .personalize-form-age-row {
  margin-top: 30px;
}
.ant-row .ant-form-item {
  margin-top: 15px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: rgb(255, 153, 0);
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: rgb(255, 153, 0);
}
.circle-img-info{
  animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s;
}
.checkbox-assessment{
    color:rgb(255,153,0); 
}


/* media query for screen size less then 600 px */
@media only screen and (max-width: 650px) {
  .side-container .ant-row {
    justify-content: center;
    /* align-items: center; */
  }
  .side-result-container .ant-row {
    justify-content: center;
  }
}

/* media query for 900px */
@media only screen and (max-width: 900px) {
  .step-text-main {
    font-size: 24px;
  }
  .step-text-main span {
    font-size: 24px;
    font-weight: 600;
  }
}

/* media query for 1024px */
@media only screen and (max-width: 1024px) {
  .button-footer {
    margin-top: 10px;
    justify-content: center;
  }
  .side-container .ant-row {
    justify-content: center;
    /* align-items: center; */
  }
  .side-result-container .ant-row {
    justify-content: center;
  }
  .circle-img-info:hover {
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
  }
}

/* media query form 320px */
@media only screen and (max-width:600px) and (min-width:320px){
    .personalize-form .gender-select-input .ant-form-item-control-input{
        padding:0px;
    }
    .personalize-form .country-select-input .ant-form-item-control-input{
        padding: 0px;
    }
    .personalize-form{
        padding-left: 12px !important;
    }
    .steps-content{
        padding-right: 0px;
    }
    .qualification-form{
        padding-right: 12px !important;
    }
    .start-wizard-footer{
        padding-right: 0px;
      }
    .button-getting-started{
        margin-right: 7px;
        margin-top: 2px;
    }
    .mobile-view-button{
        margin-top: 15px;
    }
    .wire-logo-div{
        width: 184px;
        height: 61px;
        left: 120px;
        top:30px;
        border-radius: 10px;
        border:none;
        position: inherit;
    }
    .footer-text-qualification-form {
        margin-top: 0px;
        margin-left: 0px;
        font-size: 16px;
    }
    .textbody label{
        font-size: 18px;
    }
    .ques-style {
        font-size: 18px;
      }
    .button-footer{
        margin-top: 10px;
        justify-content: center;
    }
    .question-container {
        padding: 30px 0px;
        margin-top: 30px;
    }
    .personalize-form-text span{
        color:rgb(255, 153, 0);
        display:block;
        font-size: 17px;

    }
    .personalize-form .ant-select .ant-select-selector{
        font-size: 16px;
    }
    .personalize-form .ant-form-item-control-input-content input{
        font-size: 16px;
    }
    .personalize-form .ant-row {
        margin-top: 15px;
    }
    .ant-progress .ant-progress-steps-item{
        width: 30px !important;
        height: 4px !important;
    }
    .ant-progress {
        margin-bottom: 20px;
    }
    .personalize-form-text {
        color: #FFF;
        margin-top: 50px;
        font-size: 16px;
        font-weight: 600;
        text-shadow: 1px 0 1px lightpink;
        text-align: justify;
    }
    .container-main{
        overflow: scroll;
        background-color: #652f6814;
        background-position: center;
    }
    .container-result-main{
        background-color: #652f6814;
        background-position: center;
        overflow-y: scroll;
    }
    .link-text-result{
        color: #FCB333;
        margin-left: auto;
        font-size: 19px;
        padding-top:10px
    }
    .result-subheading-container{
        font-size: 16px;
        line-height: 25px;
        text-align: justify;
    }
    .checkbox-parent-form span{
        font-size: 16px;
        line-height: 18px;
    }
    .side-result-container{
        overflow-y:visible;
        margin:auto;
    }
    .ques-result-style{
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
   .side-container{
       margin: auto;
   }
   .container {
    width: 100%;
    padding: 10px 20px 20px 20px;
  }
  .container-result {
    width: 100%;
    padding: 0px 20px 20px 20px;
  }
  .step-text-main {
    font-size: 25px;
    line-height: 35px;
    padding-top: 16px;
  }
  .step-text-main span {
    font-size: 25px;
    font-weight: 600;
  }
  .col-contentcard-cards {
    margin-top: 20px;
    margin-right: 0px;
  }
  .mobile-view-container {
    padding: 0px !important;
  }
  .personalize-form-container {
    margin-top: 20px;
    padding-right: 0px;
  }
  .ant-form-vertical .ant-form-item-label > label {
    color: #fff;
    font-size: 18.5px;
  }
  .getting-launched-button {
    width: 100px;
    height: 40px;
    margin:0px 20px;
    /* padding: 5px 15px; */
    font-size: 14px;
  }
  .qualification-form-radio label {
    font-size: 16.9px;
  }
  .question-image-container img {
    /* width: 374px;
    height: 180px; */
    object-fit: cover;
    border-radius: 6px;
    height: 202px;
  }
  .getting-start-button {
    font-size: 15px;
    margin-right:15px;
  }
  .Heading {
    font-size: 15px !important;
    font-weight: 800;
  }
  .heading {
    font-size: 16px !important;
  }
  .calender-content-card .Heading {
    margin-top: 10px;
    width: 100%;
  }
  .information-contant-card .Heading {
    width: 100%;
    margin-top: 10px;
  }
  .circle-img-info {
    width: 44px;
    height: 37px;
    margin-left: -8px;
  }

  .clocklogo {
    width: 21px;
  }
  .inner-text {
    padding-bottom: 10px;
  }
  .clocklogopic {
    margin-left: auto;
    padding: 0px 5px;
    margin-top: -1px;
  }
  .circle120 img:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.4);
  }
  .col-contentcard-cards:hover {
    transform: scale(1);
  }
  .rightoutlined-div {
    margin: 18px 20px 20px 65px;
  }
  .ant-select {
    padding: 4px;
  }
  .ant-progress-steps-outer {
    margin-left: -12px;
  }
  .ques-style {
    font-weight: 600;
  }
  .ant-input input {
    padding: 4px 0px;
  }
  .parent-form .personalize-form-phone-number .ant-input-group-addon {
    padding: 0px;
  }
  .ant-input-group-addon .ant-select {
    margin: -4px -28px;
  }
  .ant-select-arrow {
    right: 28px;
  }
  .inner-text-proceed {
    font-size: 16px;
    margin-top: 14px;
  }
  .proceed-icon {
    width: 40px;
    height: 40px;
  }
  .heading-proceed {
    font-size: 18px;
    margin-top: 0px;
  }
  .row-contentcard-proceed:hover {
    transform: scale(1.1);
  }
  .qualification-form-checkbox span {
    font-size: 16px;
  }
  .ant-row .personalize-form-age-row {
    margin-top: 20px;
  }
  .ant-row .ant-form-item {
    margin-top: 5px;
  }
  .circle-img-info:hover {
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
  }
}

   /*  insert mobile and iPad Pro 12.9" CSS here*/
@media only screen and (max-width: 1024px) and (min-width:600px){
    .steps-content{
        padding-right: 0px;
    }
    .start-wizard-footer{
        padding-right: 0px;
      }
    .button-getting-started{
        margin-right: 15px;
    }
    .qualification-form{
        padding-right: 12px !important;
    }
    .wire-logo-div{
        width: 184px;
        height: 61px;
        left: 120px;
        top:30px;
        border-radius: 10px;
        border:none;
        position: inherit;
    }
    .calender-content-card .Heading {
        margin-top: 10px;
        width: 100%;
    }
    .information-contant-card .Heading{
        width: 100%;
        margin-top: 10px;
      font-Size: 24px;
        font-weight: 800;
    }
    .footer-text-qualification-form {
        margin-top: 0px;
        margin-left: 0px;
        font-size: 16px;
    }
    .ant-form-vertical .ant-form-item-label >label {
        color: #FFF;
        font-size: 16px;
    }
    .personalize-form-text span{
        color:rgb(255, 153, 0);
        display:block;
    }
    .personalize-form .ant-select .ant-select-selector{
        font-size: 16px;
    }
    .personalize-form .ant-row {
        margin-top: 15px;
    }
    .ant-progress .ant-progress-steps-item{
        width: 55px !important;
        height: 6px !important;
    }
    .getting-launched-button{
        width: 170px;
        height: 50px;
        /* padding: 16px; */
        font-size: 16px;
    }
    .ant-progress {
        margin-bottom: 20px;
    }
    .container-main{
        overflow: scroll;
        background-color: #652f6814;
        background-position: center;
    }
    .container-result-main{
        background-color: #652f6814;
        background-position: center;
        overflow-y: scroll;
    }
    .side-result-container{
        overflow-y:visible;
        margin:auto;
    }
    .side-container{
        margin: auto;
    }
    .container {
     width: 100%;
     padding: 10px 20px 20px 20px;
    }
    .container-result{
        padding: 0px 20px 20px 20px;
    }
    .step-text-main{
        font-size: 29px;
        /* line-height: 30px; */
        margin-left: -4px;
    }
    .step-text-main span {
      font-size: 29px;
      font-weight: 600;
    }
    .mobile-view-container{
        padding: 0px !important;
    }
    .col-contentcard-cards{
        margin-top: 20px;
        margin-right: 20px;
    }
    .personalize-form-container {
        margin-top: 30px;
        padding-right: 0px;
    }
    .personalize-form-text {
        color: #FFF;
        margin-top: 50px;
        font-size: 18px;
        font-weight: 600;
        text-shadow: 1px 0 1px lightpink;
        text-align: justify;
    }
    .question-container {
        padding: 30px 0px;
        margin-top: 30px;
    }
    .question-image-container img{
        /* width: 500px; */
        /* height: 305px; */
        object-fit: cover;
    }
    .getting-start-button{
        padding: 14px 25px;
        font-size: 16px;
        font-weight: 700;
        margin-right: -37px;
    }
    .circle-img-info{
        height:60px;
        width:60px;
        margin-left: -3px;
        margin-top: 5px;
    }
    .inner-text {
        font-size: 21px;
        font-weight: 500;
        padding-bottom: 14px;
        line-height: 2.1rem;
    }
    .clocklogo{
    width:22px;
    margin-top: -2px;
  }
  .clocklogopic {
    margin-left: auto;
    padding: 0px 5px;
    margin-top: 1px;
    font-size: 22px;
  }
  .circle-img-info:hover {
    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
  }
}

@media only screen and (max-width: 1240px) and (min-width:1024px){
  .ant-progress .ant-progress-steps-item{
    width: 40px !important;
  }
  .parent-progress{
    margin-right: 30px;
  }
  .step-next-button{
    width: 140px;
  }
  .steps-content{
    padding-right: 50px;
  }
}
