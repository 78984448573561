
.checkbox-result-options .ant-radio-inner {
    border-radius: 3px !important;
    font-size: 14px;
    font-weight: 400;
    font-family: Nunito;
  
    &::after {
        border-radius: 0px;
        top: -5px;
        left: 0px;
        font-weight: bolder;
        background-color: transparent !important;
        width: 104%;
        height: 100%;
        /* content: "\2713"; */
        content: url(".././images/check-solid.svg");
        position: absolute;
    }
}
.checkbox-result-options {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.checkbox-result-options textarea {
    border-radius: 10px;
    background-color: #f5f6f8;
    padding: 10px;
    font-size: 14px;
    scrollbar-width: thin;
}
.checkbox-result-options textarea:focus {
    border: none !important;
    border-color: #fcb333 !important;
    box-shadow: none !important;
}
.checkbox-result-options .ant-list-split .ant-list-item {
    border-bottom: none;
}
.checkbox-result-options .ant-list-item-meta-avatar {
    margin-right: 6px;
}
.checkbox-result-options .ant-list-item-meta-title {
    font-size: 16px;
    font-weight: 300;
    font-family: Nunito;
    color: #fff;
}
.checkbox-result-options .ant-list-item {
    padding: 2px 0px;
}
.checkbox-result-options .ant-list-items {
    margin-top: 3px;
}
.checkbox-result-options .radio-list-icon {
    color: #86c92f;
    line-height: 27px;
}
.checkbox-result-options .ant-list {
    cursor: default;
}
.checkbox-result-options .radio-info-icon {
    padding-left: 4px;
    font-size: 14px;
    color: #ffffffd4;
}
  
.goal-collapse-container .ant-collapse-item {
    .ant-collapse-header {
        > .ant-collapse-arrow {
            display: none;
        }

        .ant-checkbox-wrapper {
            color: black;
        }
    }
}