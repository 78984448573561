@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,800;1,900&display=swap');

body{
    width: 100%;
    height: 100%;
    /* background-image:url(../images/background.png); */
    background-size: cover;
    background-attachment:fixed;
    font-family: Nunito;
    margin: 0;
    padding: 0;
}


.MainContainer{
    margin-left: 500px;
    height: 400px;
    margin-top: 250px;
    position: fixed;
    display: flex;
    flex-direction: row;
}
.slider-view{
    margin-left: 15px;
}
.circle120{
    /* height: 130px; */
    /* width: 130px; */
    margin: 30px auto;
    /* border-radius: 100px; */
    /* background-color:#D3DEE7; */
}
.circle120 img{
    /* border-radius: 50% 50% 50% 50%; */
    /* width: 130px; */
    /* height: 130px; */
    object-fit: cover;
}
.contentdiv{
    height: 250px;
    width: 235px;
    border-radius: 10px;
    background-color: #F5F6F8;
    margin-top: 60px;
    padding-top: 50px;
}

.textbody{
    margin-top: 5px;
}
.textbody label{
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #FFF;
}
.heading{
    /* width: 50%; */
    font-family: Nunito;
    font-weight: 800;
    font-size: 18px;
    margin:auto;
    line-height: 20px;
    color: #7253A2;
    text-align: center;
}

.step-right-container{
    float: right;
    padding: 0px 560px 0px 18px;;
}


.step-sub-text{
    font-weight: bold;
    font-size: 36px;
    color: #FFFFFF;
}

.list-style{
    list-style: none;
}
.ques-style {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: Nunito;
    margin-top: 50px;
    color: #FFF;
    text-align: justify;
  }
.ques-result-style{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: Nunito;
    color: #FFF;
    text-align: justify;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.user-form-main{
    display: flex;
    justify-content: center;
    padding-top: 20vh;
    width: 100vw;
    height: 100vh;
}
.manage-form-style{
    margin: auto;
}
.manage-form-style .ant-row .ant-col .ant-form-item-control-input input {
    border:none;
    border-bottom: 1px solid #E9E9E9;
}
.manage-form-style .ant-row .ant-col .ant-form-item-control-input .ant-select .ant-select-selector .ant-select-selection-search input {
    border: none;
    border-bottom:1px solid #E9E9E9 ;
}
.manage-form-style .ant-row .ant-col .ant-row .ant-col .ant-form-item-control-input .ant-form-item-control-input-content{
    border-bottom: 1px solid #E9E9E9;
}
.manage-form-style .ant-row .ant-col .ant-row .ant-col .ant-form-item-control-input 
.ant-form-item-control-input-content .ant-picker{
    border:none
}

.manage-form-style .ant-row .ant-col .ant-row .ant-col .ant-form-item-control-input 
/* .ant-form-item-control-input-content .ant-select .ant-select-selector{
    border:none !important;
    border-bottom: 1px solid #E9E9E9;
} */



.ant-select.ant-select-single.ant-select-show-arrow{
    background: transparent;
    border:none;
    border-bottom: groove;
}

.submit-button{
    margin-left: 20vw;
}

.heading-row-category{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 50%;
    margin: auto;
}
.row-content-cards-display{
    margin-top:20px ;
}
.question-image-container img{
    width: 100%;
    object-fit: cover;
}

/* background image of category page */
.col-category-background-p_verbal {
    background-image: url(../images/svg/verbalbackground.svg);
    background-repeat: no-repeat;
    background-color: #F5F6F8;
    background-blend-mode: color-burn;
    background-position: left;
    background-position-y: bottom;
    cursor: pointer;
}
.col-category-background-p_regulation {
    background-image: url(../images/svg/emotionalbackground.svg);
    background-repeat: no-repeat;
    background-color: #F5F6F8;
    background-blend-mode: color-burn;
    background-position: left;
    background-position-y: top;
    cursor: pointer;
}
.col-category-background-p_playpeople, .col-category-background-p_playobject {
    background-image: url(../images/svg/socialbackground.svg);
    background-repeat: no-repeat;
    background-color: #F5F6F8;
    background-blend-mode: color-burn;
    background-position: left;
    background-position-y: top;
    cursor: pointer;
}
.col-category-background-p_verbal:hover, .col-category-background-p_regulation:hover,
.col-category-background-p_playpeople:hover, .col-category-background-p_playobject:hover {
    border: 4px solid #d48c0e;
  }
.col-category-background-p_training {
    background-image: url(../images/svg/trainingbackground.svg);
    background-repeat: no-repeat;
    /* background-color: #F5F6F8; */
    background-color: #e9dbdb;
    background-blend-mode: color-burn;
    background-position: left;
    background-position-y: bottom;
}
.col-category-background-p_sensory{
    background-image: url(../images/svg/sensorybackground.svg);
    background-repeat: no-repeat;
    /* background-color: #F5F6F8; */
    background-color: #e9dbdb;
    background-blend-mode: color-burn;
    background-position: right;
    background-position-y: bottom;
}
.col-category-background-p_learning{
    background-image: url(../images/svg/learningbackground.svg);
    background-repeat: no-repeat;
    /* background-color: #F5F6F8; */
    background-color: #e9dbdb;
    background-blend-mode: color-burn;
    background-position: right;
    background-position-y: top;
}