.ant-tooltip-arrow-content {
  background-color: #fff;
}
.ant-tooltip-inner {
  color: #000000;
  background-color: white;
}
.ant-timeline-item-content {
  position: relative;
  /* top: -7.001px; */
  margin: 0px 0px -7px 26px;
  word-break: break-word;
  /* padding-top: 0px; */
  font-size: 16px;
  color: white;
}
.timeline {
  margin-top: 20px;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 0px;
}
.ant-timeline-item-head {
  width: 14px;
  height: 14px;
  background-color: #86c92f;
}
.ant-timeline-item-head-green {
  color: #86c92f;
  border-color: #86c92f;
}
.ant-timeline-item-tail {
  border-left: 4px solid #f0f0f0;
}
